html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

.content, .office, .contact {
  min-height: calc(100vh - 650px);
}

li {
  text-indent: 0;
  margin-top: 15px;
  padding-left: 10px;
}

.cookies-popup {
  width: 100%;
  color: #fff;
  z-index: 600;
  background: #252e39;
  justify-content: space-between;
  align-items: center;
  padding: 15px 35px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.cookies-popup__btn {
  color: #fff;
  opacity: .9;
  text-align: center;
  white-space: nowrap;
  border-style: none;
  border-width: 2px;
  border-radius: 0;
  padding: 8px .8em;
  font-size: .9em;
  font-weight: 700;
  text-decoration: none;
  display: block;
  background-color: #14a7d0 !important;
}

@media (max-width: 450px) {
  .cookies-popup__btn {
    white-space: normal;
  }
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  width: 11px;
  height: 100%;
  transform-origin: 100%;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

/*# sourceMappingURL=main.aeb6a85d.css.map */
